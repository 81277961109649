import Rails from '@rails/ujs';
export const htmlLang = $('meta[name=language]').attr('content');
export function gotoUrl(url, options) {
	window.location.replace(url);
	// Turbolinks.visit(url, options);
}
import Tab from 'bootstrap/js/dist/tab'


export function pageReload() {
	gotoUrl(location.href, {action: 'replace'});
}

export function skipPageCache() {
	const meta = document.createElement('meta');
	meta.name = 'turbolinks-cache-control';
	meta.content = 'no-cache';
	document.getElementsByTagName('head')[0].appendChild(meta);
}

export function showBootstrapTab(element) {
	element.prevObject.addClass('completed')
	if (element instanceof jQuery){
		element = element.get(0)
	}
	var tab = new Tab(element);
	tab.show();
}
const throttleTimeouts = {};
export function throttle(callback, timeout, id) {
	if (timeout == null) {
		timeout = 500;
	}
	if (id == null) {
		id = 'main';
	}
	if (throttleTimeouts[id]) {
		clearTimeout(throttleTimeouts[id]);
	}
	throttleTimeouts[id] = setTimeout((function(_this) {
		return function() {
			callback();
			return throttleTimeouts[id] = null;
		};
	})(this), timeout);
}

export function addEventListenerToCollection(collection = [], eventName, callback) {
	collection.forEach(element => {
		element.addEventListener(eventName, callback);
	});
}

export function submitByUjs(target) {
	if (target instanceof jQuery){
		target = target.get(0)
	}
	Rails.fire(target, 'submit')
}

export function spawnNew(klass, query = '') {
	document.querySelectorAll(query).forEach(element => {
		new klass(element);
	});
}

export function disableElement(element) {
	element.addEventListener('click', Rails.stopEverything);
	Rails.setData(element, 'ujs:disabled', true);
	element.classList.add('disabled');
}

export function enableElement(element) {
	element.removeEventListener('click', Rails.stopEverything);
	Rails.setData(element, 'ujs:disabled', null);
	element.classList.remove('disabled');
}

export function toggleElement(element, enable) {
	if (enable == null) {
		enable = false;
	}
	if (enable) {
		enableElement(element);
	} else {
		disableElement(element);
	}
}


export function toggleClickSelectors(enable) {
	var el, selector, _i, _len, _ref;
	if (enable == null) {
		enable = false;
	}
	selector = [Rails.buttonClickSelector.selector, Rails.linkClickSelector, 'button[data-submit]:not([form])', '#forward'].join(', ');
	_ref = document.querySelectorAll(selector);
	for (_i = 0, _len = _ref.length; _i < _len; _i++) {
		el = _ref[_i];
		toggleElement(el, enable);
	}
}

export function grep(items, callback) {
	let filtered = [];
	let len = items.length;
	let i = 0;

	for (i; i < len; i++) {
		let item = items[i];
		let cond = callback(item);
		if (cond) {
			filtered.push(item);
		}
	}

	return filtered;
}

export function unique(array = []) {
	grep(array, (el, index) => index === array.indexOf(el));
}
