export class FlashMessage {
	constructor(content) {
		this.content = content;
		this.toast = undefined;
		this.dateTime = new Date;
		this.init();
	}

	init() {
		const container = document.querySelector('#flash-container');
		container.appendChild(this.toastEl());
		this.toast = new Toast(this.toastEl());
	}

	show() {
		if (this.toast) {
			this.toast.show();
		}
	}

	toastEl() {
		const element = document.createElement('div');
		element.className = 'toast';
		element.id = `flash-${this.dateTime.getTime()}`
		element.appendChild(this.toastHeader());
		element.appendChild(this.toastBody());
		return element;
	}

	toastHeader() {
		const headerEl = document.createElement('div');
		const iconEl = document.createElement('i');
		const titleEl = document.createElement('strong');
		const timeEl = document.createElement('small');
		const closeIconEl = document.createElement('button');

		headerEl.className = 'toast-header';
		iconEl.classList.add('fas', 'fa-exclamation-triangle', 'text-danger');
		titleEl.className = 'me-auto';
		timeEl.className = 'text-muted';
		closeIconEl.className = 'btn-close';
		closeIconEl.dataset.bsDismiss = 'toast'
		titleEl.innerHTML = 'Alert';
		timeEl.innerHTML = this.currentTime();

		headerEl.appendChild(iconEl);
		headerEl.appendChild(titleEl);
		headerEl.appendChild(timeEl);
		headerEl.appendChild(closeIconEl);

		return headerEl;
	}

	toastBody() {
		const toastBodyEl = document.createElement('div');
		toastBodyEl.className = 'toast-body';
		toastBodyEl.innerHTML = this.content;
		return toastBodyEl;
	}

	currentTime() {
		let hours = this.dateTime.getHours();
		let minutes = this.dateTime.getMinutes();
		if (minutes < 10) {
			minutes = '0' + minutes;
		}
		return `${hours}:${minutes}`;
	}
}
export function closeAlerts(tag) {
	if (tag == null) {
		tag = '#flash-container';
	}
	$(tag).find('.toast').toast('hide');
	$(tag).empty();
}

export function showFlashMessage(message, type) {
	var tBody, tHeader, toast;
	if (type == null) {
		type = 'notice';
	}
	toast = $("<div class='toast toast-" + type + "'>");
	tHeader = $('<div class="toast-header">');
	$('<i class="fas mr-1 toast-icon">').appendTo(tHeader);
	$("<strong class='mr-auto'>" + type + "</strong>").appendTo(tHeader);
	$('<button data-dismiss="toast" class="close ml-2 mb-1"><span>×</span></button>').appendTo(tHeader);
	tHeader.appendTo(toast);
	tBody = $('<div class="toast-body">').html(message);
	tBody.appendTo(toast);
	toast.appendTo($('#flash-container'));
	toast.toast({
		animation: true,
		autohide: false
	}).toast('show');
}